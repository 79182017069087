<template>
  <BPayApplePayButton
    v-if="supportApplePay"
    :locale="locale"
    :button-type="buttonType"
    :props-style="propsStyle"
    @click="handleBtnClick"
  />
</template>
<script name="ApplePayBtn" setup>
import { BPayApplePayButton } from '@shein-aidc/bs-pay-toolkit-mobile-vue2'
import { SPECIAL_APPLPE_PAY_LOCALES, DEFAULT_APPLPE_PAY_LOCALE } from '@shein-aidc/bs-sdk-libs-pay'
const IS_SSR = typeof window == 'undefined'
const { lang } = IS_SSR ? {} : window.gbCommonInfo

const emit = defineEmits(['handleBtnClick', 'click'])
defineProps({
  propsStyle: {
    type: Object,
    default: () => {}
  },
  supportApplePay: {
    type: Boolean,
    default: true
  }
})

const locale = SPECIAL_APPLPE_PAY_LOCALES[lang || ''] || DEFAULT_APPLPE_PAY_LOCALE
const buttonType = 'pay'

const handleBtnClick = () => {
  emit('handleBtnClick', true)
  emit('click', true)
}

</script>
